@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body{
    background-image: url('components/images/AuroraBorealisBlurredLessSaturation.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Optional: Fixed background */
    font-family: Arial, sans-serif;}